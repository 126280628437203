
  import { styled } from '@mui/system';
  import { Box } from '@mui/material';

  const StyledAbout = styled(Box)(({theme})=>({
    padding: theme.spacing(0)
  }));

  export { StyledAbout };
  
      
      