import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Box, Typography, Grid, Container, Divider } from "@mui/material";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import { Section } from "../Section";

import { StyledAbout } from "./About.styled";

const AboutContent = ({ children }) => {
  return (
    <StyledAbout>
      <Section>
        <Container maxWidth="lg">
          <Box p={4}>
            <Typography variant="h4" color="secondary" align="center" mb={2}>
              Our Story
            </Typography>
            <Typography paragraph>
              Consequatur et dolore. Omnis commodi quis occaecati optio
              laboriosam sit voluptatum doloribus consequatur. Error veniam
              voluptatem qui esse et. Laudantium sunt est perspiciatis iure
              exercitationem nostrum id excepturi eos. Minus id optio accusamus
              itaque modi repudiandae consectetur qui.
            </Typography>

            <Box>
              <Box display="flex" justifyContent="center">
                <StaticImage
                  src="../../images/pa_boca.jpg"
                  alt="Office at Boca Raton"
                />
              </Box>
              <Box py={5}>
                <Divider />
              </Box>
              <Typography variant="h4" color="secondary" align="center" mb={4}>
                Staff
              </Typography>

              <Box>
                <Typography variant="h4" mb={2}>
                  Michael S. Joyner, MD
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <StaticImage
                      src="../../images/staff/dr-joyner.jpg"
                      alt="dr joyner photo"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography paragraph align="left">
                      Dr. Michael Joyner, a Los Angeles native, graduated from
                      Georgetown University School of Medicine in Washington, DC
                      as a United States Army Health Scholarship Recipient. He
                      completed his general surgery internship at Walter Reed
                      Army Medical Center where he served active duty as a
                      captain in the United States Army.
                    </Typography>
                    <Typography paragraph>
                      After surgical internship, Dr. Joyner served as a US Army
                      General Medical Officer at the Presidio of Monterey Army
                      Health Clinic. He was the Primary Brigade Surgeon for the
                      US Air Force 311th Training Squadron at the Defense
                      Language Institute from 1996 to 2000.
                    </Typography>
                    <Typography paragraph>
                      Dr. Joyner, a Loyola High School Alumnus, received his
                      undergraduate training at UC Berkeley and Howard
                      University. Dr. Joyner received his postgraduate training
                      in General Surgery at Morehouse School of Medicine in
                      Atlanta, Georgia. While serving as Chief General Surgery
                      resident at Grady Memorial Hospital Dr. Joyner became very
                      experienced in Trauma Surgery along with receiving The
                      Avon Breast Cancer Center Award for service and care in
                      surgical oncology and reconstructive surgery. He served as
                      a Christin M. Kleinert Hand Fellow in 2005 and a visiting
                      microsurgical scholar to Chang-Gung Memorial Hospital,
                      Taipei, Taiwan in 2006.
                    </Typography>
                    <Typography paragraph>
                      Prior to The Joyner Hand, PA, Dr. Joyner was a staff Hand
                      and Microsurgeon for Kleinert, Kutz and Associates in
                      Lexington, Kentucky. He was also a clinical instructor for
                      the University of Louisville.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box my={`140px`} />
              {/* <Box>
                <Typography variant="h4" mb={2}>
                  Chad J. Nadler, MD
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <StaticImage
                      src="../../images/staff/dr-nadler.jpg"
                      alt="dr nadler photo"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography paragraph align="left">
                      Dr. Chad Nadler grew up in Dix Hills, NY and received his
                      Bachelors of Science from Cornell University. He then went
                      on to earn his MD from SUNY Upstate Medical University in
                      Syracuse, NY. He completed his General Surgery Internship
                      at Hahnemann Hospital of Drexel University in
                      Philadelphia, PA. Dr. Nadler then completed his General
                      Surgery Residency at West Virginia University and Hand
                      Surgery Fellowship at the University of Mississippi.
                    </Typography>
                    <Typography paragraph align="left">
                      Dr. Nadler initially practiced in South Florida before
                      moving to California for 5 years. He returned to South
                      Florida to be closer to family and joined The Joyner Hand,
                      PA. Dr. Nadler specializes in minimally invasive carpal
                      tunnel and cubital tunnel repair. He is proficient in hand
                      and wrist fractures, including the distal radius, along
                      with tendon and microvascular nerve repair. He has
                      extensive hand elective and trauma surgery experience. Dr.
                      Nadler is part of the voluntary faculty of the Broward
                      Health Surgery Residency and has won multiple teaching
                      awards. He is an avid tennis player and runner and loves
                      traveling with his kids.
                    </Typography>
                  </Grid>
                </Grid>
              </Box> */}
            </Box>
          </Box>
        </Container>
      </Section>
      <Section secondary>
        <Container maxWidth="lg">
          <Box py={4}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  color="common.white"
                  align="center"
                  mb={2}
                >
                  <strong>The Surgeons Hand</strong>
                </Typography>

                <Box display="flex" justifyContent="center">
                  <Box>
                    <Typography color="common.white">
                      To complete the commands of the mind
                    </Typography>
                    <Typography color="common.white">
                      And the emotions of the heart{" "}
                    </Typography>
                    <Typography color="common.white">
                      To reach for the surgical drapes{" "}
                    </Typography>
                    <Typography color="common.white">
                      To clinch the towel clips shut{" "}
                    </Typography>
                    <Typography color="common.white">
                      To palpate the undesirable mass{" "}
                    </Typography>
                    <Typography color="common.white">
                      To draw the incision site and{" "}
                    </Typography>
                    <Typography color="common.white" mb={2}>
                      To silently speak to the surgical team
                    </Typography>

                    <Typography color="common.white">To dissect </Typography>
                    <Typography color="common.white">To touch </Typography>
                    <Typography color="common.white">To remove</Typography>
                    <Typography color="common.white">To create and </Typography>
                    <Typography color="common.white">
                      {" "}
                      mb={2}To repair
                    </Typography>
                    <Typography color="common.white">
                      To bandage and{" "}
                    </Typography>
                    <Typography color="common.white" mb={2}>
                      To document
                    </Typography>
                    <Typography color="common.white">
                      Feel the hands of the family{" "}
                    </Typography>
                    <Typography color="common.white">
                      Express comfort to the friends
                    </Typography>
                    <Typography color="common.white">
                      {" "}
                      mb={2}And write the love ones
                    </Typography>
                    <Typography color="common.white" mb={2}>
                      For it is our Hands that act as the
                    </Typography>
                    <Typography color="common.white" mb={2}>
                      Harmonious
                    </Typography>
                    <Typography color="common.white" mb={2}>
                      Answer to a
                    </Typography>
                    <Typography color="common.white" mb={2}>
                      Never Ending
                    </Typography>
                    <Typography color="common.white" mb={2}>
                      Demand
                    </Typography>
                    <Typography color="common.white">
                      From the mind and{" "}
                    </Typography>
                    <Typography color="common.white">
                      The emotions of the heart
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Section>
    </StyledAbout>
  );
};

const About = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "hero-about-us.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const pluginImage = getImage(placeholderImage);

  return (
    <>
      <BgImage image={pluginImage} style={{ minHeight: 375 }}>
        <Box
          sx={{
            height: 375,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignItems: `middle`,
          }}
        >
          <Box>
            <Typography variant="h3" align="center" color="common.white">
              <strong> About</strong>
            </Typography>
            <Typography variant="h4" align="center" color="common.white">
              Boca Raton and East Delray Beach Hand Surgeon and More …
            </Typography>
          </Box>
        </Box>
      </BgImage>
      <AboutContent />
    </>
  );
};

export default About;
