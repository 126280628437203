import * as React from "react";
import { Layout } from "../components/Layout";
import { About } from "../components/About";

export default function AboutPage() {
  return (
    <Layout>
      <About />
    </Layout>
  );
}
